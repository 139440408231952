import {useEffect, useRef, useState} from "react";
import { elements } from "../_data";
import Element from "./Element";
import CloseIcon from '@mui/icons-material/Close';

interface PeriodicTableProps {
  enabledElements: number[];
  enabledElmOxides: Record<string, string[]>;
  activeElements: string[];
  onClickElement: (symbol: string) => void;
    setSelectedElmOxide: (oxide: Map<string, string>) => void;
    onClickOxide: (oxide: string) => void;
}

export default function PeriodicTable({
                                        enabledElements,
                                        enabledElmOxides,
                                        activeElements,
                                        onClickElement,
                                          setSelectedElmOxide,
                                          onClickOxide,
                                      }: PeriodicTableProps) {

  const [displayElementNum, setDisplayElementNum] = useState(-1);

  const [showOxidesX, setShowOxidesX] = useState(0);
  const [showOxidesY, setShowOxidesY] = useState(0);
  const [showOxides, setShowOxides]   = useState(false);
  const [oxides, setOxides] = useState<string[]>([]);
  const [selectedSymbol, setSelectedSymbol] = useState("");

    // Reference to the oxide list container for click detection
    const oxideListRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Function to handle clicks outside the oxide list
        const handleClickOutside = (event: MouseEvent) => {
            if (
                oxideListRef.current &&
                !oxideListRef.current.contains(event.target as Node)
            ) {
                setShowOxides(false);
            }
        };

        // Attach the event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

  const populateElements = (start: number, end: number) => {
    return elements
        .filter((element) => element.atomicNum >= start && element.atomicNum <= end)
        .map((element) => {
          const isActive = activeElements.includes(element.symbol);
          return (
              <Element
                  key={element.atomicNum}
                  data={element}
                  onClickElement={onClickElement}
                  showInfo={showInfo}
                  enabled={enabledElements.includes(element.atomicNum)}
                  active={isActive}
                  setSelectedSymbol={setSelectedSymbol}
                  setShowOxidesX={setShowOxidesX}
                  setShowOxidesY={setShowOxidesY}
                  setShowOxides={setShowOxides}
                  setOxides={setOxides}
                  enabledElmOxides={enabledElmOxides}
              />
          );
        });
  };

  const showInfo = (atomicNum: number) => {
    // setDisplayElementNum(atomicNum);
    // setShowOxides(atomicNum > 0);
    // setOxides(enabledElmOxides[selectedSymbol] || []);
  };

  const displayElm = elements.find((e) => e.atomicNum === displayElementNum);

  return (
      <div className="max-w-max">
        <div className="grid grid-cols-[repeat(17,_auto)_1fr] auto-cols-fr grid-flow-row gap-0.5">
          {/* Periodic table elements */}
          {populateElements(1, 4)}
          {populateElements(5, 57)}

          {/* Display the selected element details */}
          {/*{displayElm && (*/}
          {/*    <div className={`element-box ${displayElm.category}`}>*/}
          {/*      <div className="absolute top-2 left-2 text-xs">*/}
          {/*        {displayElm.atomicNum}*/}
          {/*      </div>*/}
          {/*      <div className="text-xl">{displayElm.symbol}</div>*/}
          {/*      <div className="absolute bottom-2 text-xs">{displayElm.name}</div>*/}
          {/*    </div>*/}
          {/*)}*/}

          {/* Lanthanoids split 72-89 */}
          {populateElements(72, 89)}
          {/* Actinoids split 104-119*/}
          {populateElements(104, 118)}
          {/* Lanthenoids 58-71*/}
          {populateElements(58, 71)}
          {/* Actinoids 90-103 */}
          {populateElements(90, 103)}
        </div>
          {/* Conditionally render the oxide list on hover */}
          {showOxides && oxides.length > 0 && (
              <div
                  ref={oxideListRef} // Attach the ref to the oxide list container
                  className="oxide-list absolute bg-gray-300 shadow-lg border rounded-md mt-1 z-100"
                  style={{
                      left: showOxidesX,
                      top: showOxidesY,
                      position: "absolute",
                  }}
              >
                  <div className="relative w-full bg-[#03457b] rounded-sm pb-1 px-8 flex justify-center items-center">
                      <span className="text-white text-center p-2">OXIDES OF {selectedSymbol}</span>
                      <CloseIcon
                          className="absolute top-1 right-2 text-white cursor-pointer h-4 w-4 bg-red-600 rounded-md"
                          onClick={() => setShowOxides(false)}
                      />
                  </div>
                  {oxides.map((oxide, index) => (
                      <div
                          key={index}
                          className="oxide-item p-1 cursor-pointer py-2 px-4 rounded-md"
                          onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering element click
                              setSelectedElmOxide(new Map([[`${displayElementNum}`, oxide]]));
                              onClickOxide(oxide);
                          }}
                      >
                          <div className="bg-orange-500 flex justify-center items-center border-2 border-orange-500 text-white hover:text-white px-6 py-1 rounded-md cursor-pointer hover:bg-orange-700 hover:border-orange-700">
                              {oxide}
                          </div>
                      </div>
                  ))}
              </div>
          )}
      </div>
  );
}
