import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import {Dialog, DialogContent, LinearProgress, TableSortLabel} from "@mui/material";

import * as apiDatabase from "../../api/database";
import {ElementFilter, NumberRange, OxideFilter} from "./Database";
import {CSVtoArray} from "../../utils";
import {DatabaseOptions} from "../Constants/base";
import * as React from "react";
import {Simulate} from "react-dom/test-utils";
import {filter} from "d3";
import ScatterPlotWrapper from "./Graph/ScatterPlotWrapper";
interface DataDisplayTableProps {
  parsedData: Array<Array<string>>;
  strSearch: string;
  totalCount: number;
  selectedElmsToFilter: Map<string, ElementFilter>;
  selectedOxidesToFilter: Map<string, OxideFilter>;
  propertiesToValueRange: Map<string, NumberRange>;

  activeProperties: string[];

  setIsLoading: (isLoading: boolean) => void;

  setErrorMsg: (errorMsg: string) => void;

  setData: (data: string) => void;

  setParsedData: (parsedData: Array<Array<string>>) => void

  selectedDb: number;
  setSnackbarOpen: (flag: boolean) => void;
}

type Order = "asc" | "desc";

export default function DataDisplayTable({
  parsedData,
  strSearch,
  totalCount,
  selectedElmsToFilter,
  selectedOxidesToFilter,
  propertiesToValueRange,
  activeProperties,
  setIsLoading,
  setErrorMsg,
  setData,
  setParsedData,
  selectedDb,
  setSnackbarOpen,
}: DataDisplayTableProps) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [orderByCol, setOrderByCol] = useState("");
  const [order, setOrder] = useState<Order>("asc");
  const [sortedData, setSortedData] = useState<Array<Array<string>>>([]);
  const [blobData, setBlobData] = useState("");

  const [currentRows, setCurrentRows] = useState<Array<Array<string>>>([]);

  const [downloadProgress, setDownloadProgress] = useState(0);

  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (parsedData.length !== sortedData.length) setSortedData([...parsedData]);
  }, [parsedData, sortedData.length]);

  useEffect(() => {
    const rows = sortedData
        .slice(1)
        //.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .slice(0, rowsPerPage);

    setCurrentRows(rows);
  }, [sortedData, page, rowsPerPage]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  useEffect( () => {

    setIsLoading(true);

    const fetchData = async () => {
      const propertiesToValueRangePayload = new Map<string, NumberRange>();
      for (const p of activeProperties) {
        let vRange = propertiesToValueRange.get(p);
        if (!vRange) vRange = { from: 0, to: 0 };
        propertiesToValueRangePayload.set(p, vRange);
      }

      const selectedLabel = DatabaseOptions.find(obj => obj.value === selectedDb)?.label || "";

      // Combine both selectedElmsToFilter and selectedOxidesToFilter
      const combinedFilters = new Map<string, ElementFilter | OxideFilter>([
        ...selectedElmsToFilter,
        ...selectedOxidesToFilter,
      ]);

      const res = await apiDatabase.query(
          combinedFilters,
          propertiesToValueRangePayload,
          page+1,
          20,
          selectedDb
      );

      setIsLoading(false);

      if (res.error) setErrorMsg(res.error.response?.data);
      if ((res.data as string).toLowerCase().indexOf("error") !== -1) {
        setData("");
        setErrorMsg("Failed to fetch data");
        setSnackbarOpen(true);
        return;
      }

      const parsed: Array<Array<string>> = [];

      for (const line of res.data.split("\n")) {
        const cols = CSVtoArray(line);
        if (cols.length > 1) parsed.push(cols);
      }

      setBlobData(res.data)
      setParsedData(parsed);
      setSortedData([...parsed]);

      //console.log("page: ", parsed);

      // Process the fetched data here
    };

    fetchData().then();

  }, [page, totalCount]);

  const handleSort = (header: string) => (event: React.MouseEvent<unknown>) => {
    setOrderByCol(header);

    const idx = sortedData[0].indexOf(header);
    const headers = sortedData.shift();
    if (headers === undefined) return;

    let newOrder: Order = order === "asc" ? "desc" : "asc";
    setOrder(newOrder);

    sortedData.sort(
      ((idx) => {
        return function (a, b) {
          if (newOrder === "asc") {
            if (!isNaN(+a[idx]) && !isNaN(+b[idx])) {
              return +a[idx] - +b[idx];
            }
            return a[idx] === b[idx] ? 0 : a[idx] < b[idx] ? -1 : 1;
          } else {
            if (!isNaN(+a[idx]) && !isNaN(+b[idx])) {
              return +b[idx] - +a[idx];
            }
            return a[idx] === b[idx] ? 0 : a[idx] > b[idx] ? -1 : 1;
          }
        };
      })(idx)
    );

    setSortedData([headers, ...sortedData]);
  };

  const onClickDownload = async () => {
    try {
      setIsDownloading(true);

      let progress = 10;
      setDownloadProgress(progress);

      const propertiesToValueRangePayload = new Map<string, NumberRange>();
      for (const p of activeProperties) {
        let vRange = propertiesToValueRange.get(p);
        if (!vRange) vRange = { from: 0, to: 0 };
        propertiesToValueRangePayload.set(p, vRange);
      }

      const selectedLabel = DatabaseOptions.find(obj => obj.value === selectedDb)?.label || "";

      // Combine both selectedElmsToFilter and selectedOxidesToFilter
      const combinedFilters = new Map<string, ElementFilter | OxideFilter>([
        ...selectedElmsToFilter,
        ...selectedOxidesToFilter,
      ]);

      const response = await apiDatabase.awsDownload(
          combinedFilters,
          propertiesToValueRangePayload,
          page+1,
          20,
          selectedDb,
          selectedLabel
      );

      if (!response || !response.data.status_code || response.data.status_code !== 200 ) {
        setErrorMsg('Failed to download the file')
        setIsDownloading(false);
        setDownloadProgress(100);
        setSnackbarOpen(true);
        return
      }

      // Step 2: Simulate progress while downloading
      await new Promise<void>((resolve) => {
        const interval = setInterval(() => {
          if (progress >= 100) {
            clearInterval(interval);
            setDownloadProgress(0);
            setIsDownloading(false);
            resolve(); // Resolve the promise when the progress completes
          } else {
            progress += 10;
            setDownloadProgress(progress);
          }
        }, 500);
      });

      downloadFileFromLink(response.data.data.url, selectedLabel);
    } catch (error) {
      console.error("Error downloading file:", error);
      setIsDownloading(false);
      setDownloadProgress(0);
      setErrorMsg('Failed to download the file');
      setSnackbarOpen(true);
    }
  }

  function downloadFileFromLink(url: string, filename: string) {
    // Use fetch to download the file data as a Blob
    fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.blob();
        })
        .then(blob => {
          // Create a link element
          const element = document.createElement("a");
          const blobUrl = URL.createObjectURL(blob);

          // Set the href to the blob URL
          element.href = blobUrl;
          element.download = filename; // The filename that will be downloaded

          // Append to the document body and trigger the download
          document.body.appendChild(element);
          element.click();

          // Clean up the element and revoke the blob URL
          document.body.removeChild(element);
          URL.revokeObjectURL(blobUrl);
        })
        .catch(error => {
          console.error('There was an issue with the download:', error);
          // Fallback: Open the file in a new tab if something goes wrong
          window.open(url, "_blank");
        });
  }





  return (
      <>
        {sortedData.length > 1 ? (
            <Paper
                sx={{
                  width: "100%",
                  overflow: "hidden",
                  padding: "1rem 1rem 0 1rem",
                }}
            >
              <TableContainer sx={{maxHeight: 440, margin: 0}}>
                <Table stickyHeader size="small" aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell className="bg-sky-900 text-white w-max">
                        <div className="flex inline-block max-w-full whitespace-nowrap overflow-hidden text-ellipsis justify-center">Row No.</div>
                      </TableCell>
                      {sortedData[0].map((col) => (
                          <TableCell
                              className="bg-sky-900 text-white w-max"
                              key={col}
                          >
                            <TableSortLabel
                                className="text-white"
                                active={orderByCol === col}
                                direction={orderByCol === col ? order : "asc"}
                                onClick={handleSort(col)}
                            >
                              <div className="flex inline-block max-w-full whitespace-nowrap overflow-hidden text-ellipsis justify-center">
                                {col}
                              </div>
                            </TableSortLabel>
                          </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentRows.map((row, rowIdx) => {
                      if (row.join().indexOf(strSearch) === -1) return <></>;

                      return (
                          <TableRow key={rowIdx}>
                            <TableCell>
                              <div className="inline-block max-w-full whitespace-nowrap overflow-hidden text-ellipsis text-center">
                                {rowIdx + 1 + (page * rowsPerPage)}
                              </div>
                            </TableCell>
                            {row.map((val, colIdx) => (
                                <TableCell key={colIdx}>
                                  <div className="inline-block max-w-full whitespace-nowrap overflow-hidden text-ellipsis text-center">{val}</div>
                                </TableCell>
                            ))}
                          </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                  rowsPerPageOptions={[rowsPerPage]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
              />
              <Dialog open={isDownloading} onClose={() => {}} fullWidth maxWidth="sm" >
                <DialogContent className="flex flex-col items-center p-5">
                  <div className="w-full mb-4">
                    <LinearProgress variant="determinate" value={downloadProgress} />
                  </div>
                  <p className="text-center">Downloading... {downloadProgress}%</p>
                </DialogContent>
              </Dialog>


              <ScatterPlotWrapper
                  result={parsedData}
                  activeProperties={activeProperties}
                  propertiesToValueRange={propertiesToValueRange}
                  selectedElmsToFilter={selectedElmsToFilter}
                  selectedOxidesToFilter={selectedOxidesToFilter}
                  selectedDb={selectedDb}
              />

              {/* Your existing button or component that triggers the download */}
              <div className="flex justify-center items-center gap-4 p-4">
                {/* Your trigger component, e.g., a download button */}
                <button
                    className="bg-sky-700 hover:bg-sky-900 text-white py-2 px-4 rounded"
                    onClick={onClickDownload}
                >
                  Download Database
                </button>
              </div>

            </Paper>
        ) : (
            <></>
        )}
      </>
  );
}
